import { initializeApp } from 'firebase/app';
import {
  experimentalSetDeliveryMetricsExportedToBigQueryEnabled,
  getMessaging,
  isSupported,
  onBackgroundMessage,
} from 'firebase/messaging/sw';

import {
  getToken,
    getMessaging as getMessaging2
} from 'firebase/messaging';

declare var self: ServiceWorkerGlobalScope;

self.addEventListener('install', (event) => {
  console.log(self);
  console.log(event);
});

const app = initializeApp({
  apiKey: 'AIzaSyA8LwQebcuNPPvzmdzhvp6JKRfr8GXGZD0',
  appId: '1:9948702423:web:46205d36fa1f978570b292',
  messagingSenderId: '9948702423',
  projectId: 'dianran-live',
  authDomain: 'dianran-live.firebaseapp.com',
  databaseURL: 'https://dianran-live-default-rtdb.asia-southeast1.firebasedatabase.app',
  storageBucket: 'dianran-live.appspot.com',
  measurementId: 'G-S0K48B5TM9',
});

isSupported().then((isSupported) => {
  if (isSupported) {
    const messaging = getMessaging(app);
    getToken(messaging, {vapidKey: "BDe4qqX8BfD1k07z2hfd3fppsPDL6URHA6Yy0CJfunSHfHgVoYJL_QlQeNu9anKQT52g_Fc0PKn7R-j9w7pBMJo"});
    experimentalSetDeliveryMetricsExportedToBigQueryEnabled(messaging, true);

    onBackgroundMessage(messaging, ({ notification: notification }) => {
      const { title, body, image } = notification ?? {};

      if (!title) {
        return;
      }

      self.registration.showNotification(title, {
        body,
        icon: image || '/assets/img/android-icon-72x72.png',
      });
    });
  }
});
